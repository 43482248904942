<template>

  <div v-if="activeProjectAgentAnalyticsUrl == 'null'">
    <div class="agentAnalytics">
    <div class="displayFlexColumn" style="gap:16px">
      <div class="displayFlex displayFlexAndSpaceBetween">
        <span class="adminTopicModelingDashboard__title">Analytics</span>
      </div>
      <div class="projectSettings__title">
          Agent Analytics
      </div>
      <!--DATE-PICKER-->
      <div class="displayFlex agentAnalytics__datepickerWrapper">
        <div class="displayFlexColumn">
          <div class="agentAnalytics__datepicker--inputWrapper displayFlex">
            <input class="agentAnalytics__datepicker--input" :value="'FROM '+ startdate +' TO '+ enddate"/>
            <button :class="['agentAnalytics__datepicker--dropdownButton' , datepickerVisibility ? 'arrowup' : 'arrowdown']" @click="showOrHideDatepicker"></button>
          </div>
          <div class="agentAnalytics__datepicker">
            <v-date-picker color="indigo" :style="datepickerVisibility ? 'display:block' : 'display:none'" v-model="range"  is-range></v-date-picker>
          </div>
        </div>
      </div>
      <!--TOPIC DISTIBUTION-->
      <div class="displayFlex">
        <span class="agentAnalytics__title">Topic Distribution</span>
        <span class="agentAnalytics__questionLabelBlack">Analyzed conversations : {{classifiedConversations}}/{{totalConversations}} </span>
      </div>
      <!--topic distribution graph basic-->
      <apexchart height="80%" width="100%" type="treemap" :series="seriesFromAIKNY" :options="treemapChartOptions"></apexchart>
      <div @click="exportTopic" class="displayFlex agentAnalytics__exportTopicWrapper">
        <i class="agentAnalytics__exportTopicIcon"></i>
        <span class="marginAutoZero" style="padding-right:60px">export topic</span>
      </div>
       <!--FEEDBACK-->
      <div class="displayFlex">
        <span class="agentAnalytics__title">Sentiment</span>
        <span class="agentAnalytics__questionLabel">How good is your customer service?</span>
      </div>
      <div>
        <span>Topic selected:</span>
        <div class="select" style="width:40%">
          <select id="standard-select" @change="checkTopicSentiment($event)">
            <option value="null" ><label>All</label></option>
            <option class="adminModal__checkboxList" v-for="(topic) in topics" :key="topic.id" :value="topic.topic_id"><!--v for--->
              <label >{{topic.topic_name}}</label>
            </option>
          </select>
        </div>
      </div>
      <!--feedback graph-->
      <div class="agentAnalytics__feedbackGraph">
        <div :style="`flex-grow:${this.positive}; background-color:#585196; border-radius: inherit;`"><p class="agentAnalytics__feedbackLabel">Positive {{this.positive}}</p></div>
        <div :style="`flex-grow:${this.negative}; background-color:#B9B4E1; border-radius: inherit;`"><p class="agentAnalytics__feedbackLabel black">Negative {{this.negative}}</p></div>
        <div :style="`flex-grow:${this.neutral}; background-color:#F5F3FC; border-radius: inherit;`"><p class="agentAnalytics__feedbackLabel black">Neutral {{this.neutral}}</p></div>
      </div>
      <div @click="exportSentiment" class="displayFlex agentAnalytics__exportFeedbackWrapper">
        <i class="agentAnalytics__exportTopicIcon"></i>
        <span class="marginAutoZero" style="padding-right:60px">export sentiment</span>
      </div>
    </div>
  </div>
  </div>

  <!-- Import Google Data Studio. -->
  <div v-else>
    <iframe :src="activeProjectAgentAnalyticsUrl" frameborder="0" style="border:0; padding-left:80px; width:100%; height:100vh" allowfullscreen></iframe>
  </div>
  
</template>

<script>
import axios from "axios"; // TODO: refactor needed
import _ from 'lodash';
import cookies from "@/common/cookies";


import {
  AdminModal,
  Analytics,
  AdminSidebar,
} from "@/components";

export default {
  name: "AgentAnalytics",
  components: {
    AdminModal,
    Analytics,
    AdminSidebar
  },
  data() {
    return {
      positive: 1,
      negative: 1,
      neutral: 1,
      channelsData: [],
      range:{
        start :  new Date(),
        end : new Date()
      },
      topics:[],
      channelID: Number(),
      topicID: Number(),
      datepickerVisibility : false,
      threshold :0.0,
      seriesFromAIKNY:[],
      activeUser: {},
      activeProjectID: localStorage.getItem("selectedProjectID"),
      activeProjectAgentAnalyticsUrl: localStorage.getItem("activeProjectAgentAnalyticsUrl"),
      totalConversations:Number(),
      classifiedConversations:Number(),

      treemapChartOptions: {
        legend: {
          show: false
        },
        plotOptions: {
          colors: [
              '#A1CAD0',
              '#F9D7A7',
              '#F2B293',
              '#EF6BA6',
              '#AFA9E4',
              '#77B0A4'
            ],
          treemap: {
            distributed: true,
          }
        },

        chart: {
          height: 350,
          type: 'treemap',
          toolbar:{
            show: false
          }
        },
        title: {
          title: {
            align: 'center'
          }
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '28px',
          },
          formatter: function(text, op) {
            return [text, op.value]
          },
          offsetY: -4
        },
      },
    } 
  },
  watch:{
    range(){
      console.log("START: " + this.range.start.toISOString().slice(0, 10).replace('T', ' '))
      console.log("END: " + this.range.end.toISOString().slice(0, 19).replace('T', ' '))
      this.getTreemapSeries();
      this.checkTopicSentiment(null);
    }
  },
  computed:{
    startdate(){
      return this.range.start.toISOString().slice(0, 10).replaceAll('-', '/')
    },
    enddate(){
      return this.range.end.toISOString().slice(0, 10).replaceAll('-', '/')
    }
  },
  created() {
    this.authorizeUser();
  },
  methods: {

    getFormattedDate(date) {
      var month = date.getMonth();
      var day = date.getDate();

      month = (month < 10 ? "0" : "") + month;
      day = (day < 10 ? "0" : "") + day;

      var str = date.getFullYear() + month + day;

      return str;
    },


    async getClassifiedConversationsInfo(){
      await axios.get(`project/${this.activeProjectID}/action/get-classified-conversations-info`).then((res) => {
        this.totalConversations = res.data.data.total_conversations
        this.classifiedConversations = res.data.data.classified_conversations
      }
        
      )
    },
    
    async exportTopic(){
      let payload = {start_timestamp: this.range.start, end_timestamp: this.range.end}
      await axios
        .post(`/project/${this.activeProjectID}/action/extract-associated-topic-volumes`, payload)
        .then(res => {
          const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
          const fileLink = document.createElement("a");
          fileLink.setAttribute("download", "conv_topics_" + this.getFormattedDate(this.range.start) + "_" + this.getFormattedDate(this.range.end)  + ".csv");
          fileLink.href = fileUrl;
          document.body.appendChild(fileLink);
          fileLink.click();
        });
    },

    async exportSentiment(){
      let payload = {start_timestamp: this.range.start, end_timestamp: this.range.end}
      if(this.topicID){ 
        payload.topic_id = this.topicID
      }
      await axios
        .post(`/project/${this.activeProjectID}/action/extract-sentiment-topic-volumes`, payload)
        .then(res => {
          const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
          const fileLink = document.createElement("a");
          fileLink.setAttribute("download", "conv_sentiment_" + this.getFormattedDate(this.range.start) + "_" + this.getFormattedDate(this.range.end)  + ".csv");
          fileLink.href = fileUrl;
          document.body.appendChild(fileLink);
          fileLink.click();
        });
    },

    async checkTopicSentiment(e){
      let event = e == null ? null : e
      if(event){
        this.topicID = event.target.value === "null" ? null : event.target.value;
      }
      if(event == null || this.topicID == null){
        this.topicID = null
        var datafromAIKY = await axios.post(`/project/${this.activeProjectID}/action/get-sentiment-topic-volumes` ,{
          start_timestamp: this.range.start,
          end_timestamp: this.range.end
        })
      }else{
        console.log("TOPIC ID:" + this.topicID)
        var datafromAIKY = await axios.post(`/project/${this.activeProjectID}/action/get-sentiment-topic-volumes` ,{
          start_timestamp: this.range.start,
          end_timestamp: this.range.end,
          topic_id: this.topicID
        })
      }
      if(datafromAIKY){
        this.positive = datafromAIKY.data.data.data.positive
        this.negative = datafromAIKY.data.data.data.negative
        this.neutral = datafromAIKY.data.data.data.neutral
      }else{
        this.positive = 0
        this.negative = 0
        this.neutral = 0
      }
    },

    showOrHideDatepicker(){
      this.datepickerVisibility = !this.datepickerVisibility
    },

    async getTreemapSeries(){

      var datafromAIKY = await axios.post(`/project/${this.activeProjectID}/action/get-associated-topic-volumes` ,{
        start_timestamp: this.range.start,
        end_timestamp: this.range.end,
        threshold: 0
      })
      
      this.topics = datafromAIKY.data.data.data;

        var graphData = {}

          graphData =  _.map(this.topics, function(obj){
            return {
              x: obj.topic_name,
              y: obj.conv_associated 
            }
          })   
        this.seriesFromAIKNY = [{
          data: graphData
        }]
      
    },

    /**
     * Authorize the user by either `window.localStorage', `window.sessionStorage`, or a cookie-stored token as a fallback.
     */
    authorizeUser() {
      let token;
      if (localStorage || sessionStorage) {
        token = localStorage.getItem("jwt") ? localStorage.getItem("jwt") : sessionStorage.getItem("jwt");
      } else {
        token = cookies.getCookie("jwt");
      }
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    },
  },

  async beforeMount(){
    var end_time = new Date();
    var year = end_time.getFullYear();
    var month = end_time.getMonth();
    var day = end_time.getDate();
    var start_time = new Date(year, month - 3, day);
    this.range.start = start_time
    this.range.end = end_time
    this.getClassifiedConversationsInfo()
  },

  mounted(){
    this.getTreemapSeries()
    this.checkTopicSentiment(null)
  }


  
};
</script>